import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import renderSVGToBlob from './renderSVGToBlob';

export function zipFilename(name: string) {
  return `${name}_information_pack`
    .replace(/[^a-z\d]/gi, '_')
    .toLowerCase()
    .concat('.zip');
}

const renderToZip = async (
  name: string,
  isLearnerCountExceeded: boolean = false,
) => {
  const fileName = zipFilename(name);
  const zip = new JSZip();
  const consciousWheelDisplayInitials = await renderSVGToBlob(
    'Conscious_Wheel_displayInitials',
    false,
    1500,
    1500,
  );
  const consciousWheelHideInitials = await renderSVGToBlob(
    'Conscious_Wheel_displayInitials_false',
    false,
    1500,
    1500,
  );
  const consciousWheelDisplayInitials8Colour = await renderSVGToBlob(
    'Conscious_Wheel_displayInitials_8_colour',
    false,
    1500,
    1500,
  );
  const consciousWheelHideInitials8Colour = await renderSVGToBlob(
    'Conscious_Wheel_displayInitials_false_8_colour',
    false,
    1500,
    1500,
  );
  const lessConsciousWheelDisplayInitials8Colour = await renderSVGToBlob(
    'LessConscious_Wheel_displayInitials_8_colour',
    false,
    1500,
    1500,
  );
  const lessConsciousWheelHideInitials8Colour = await renderSVGToBlob(
    'LessConscious_Wheel_displayInitials_false_8_colour',
    false,
    1500,
    1500,
  );
  const lessConsciousWheelDisplayInitials = await renderSVGToBlob(
    'LessConscious_Wheel_displayInitials',
    false,
    1500,
    1500,
  );
  const lessConsciousWheelHideInitials = await renderSVGToBlob(
    'LessConscious_Wheel_displayInitials_false',
    false,
    1500,
    1500,
  );
  const consciousDominantEnergies = await renderSVGToBlob(
    'Conscious_Dominant_Energies',
    false,
    1440,
    1170,
  );
  const lessConsciousDominantEnergies = await renderSVGToBlob(
    'LessConscious_Dominant_Energies',
    false,
    1440,
    1170,
  );
  const consciousPreferredEnergies = await renderSVGToBlob(
    'Conscious_Preferred_Energies',
    false,
    1440,
    1170,
  );
  const lessConsciousPreferredEnergies = await renderSVGToBlob(
    'LessConscious_Preferred_Energies',
    false,
    1440,
    1170,
  );

  if (!isLearnerCountExceeded) {
    const ConsciousTeamTable = await renderSVGToBlob(
      'Conscious_Team_Table',
      true,
    );
    const LessConsciousTeamTable = await renderSVGToBlob(
      'LessConscious_Team_Table',
      true,
    );
    zip.file('ConsciousTeamTable.png', ConsciousTeamTable);
    zip.file('LessConsciousTeamTable.png', LessConsciousTeamTable);
  }

  zip.file(
    'consciousWheelDisplayInitials(4-Colour).png',
    consciousWheelDisplayInitials,
  );
  zip.file(
    'consciousWheelHideInitials(4-Colour).png',
    consciousWheelHideInitials,
  );
  zip.file(
    'consciousWheelDisplayInitials(8-Colour).png',
    consciousWheelDisplayInitials8Colour,
  );
  zip.file(
    'consciousWheelHideInitials(8-Colour).png',
    consciousWheelHideInitials8Colour,
  );
  zip.file(
    'lessConsciousWheelDisplayInitials(4-Colour).png',
    lessConsciousWheelDisplayInitials,
  );
  zip.file(
    'lessConsciousWheelHideInitials(4-Colour).png',
    lessConsciousWheelHideInitials,
  );
  zip.file(
    'lessConsciousWheelDisplayInitials(8-Colour).png',
    lessConsciousWheelDisplayInitials8Colour,
  );
  zip.file(
    'lessConsciousWheelHideInitials(8-Colour).png',
    lessConsciousWheelHideInitials8Colour,
  );
  zip.file('consciousDominantEnergies.png', consciousDominantEnergies);
  zip.file('lessConsciousDominantEnergies.png', lessConsciousDominantEnergies);
  zip.file('consciousPreferredEnergies.png', consciousPreferredEnergies);
  zip.file(
    'lessConsciousPreferredEnergies.png',
    lessConsciousPreferredEnergies,
  );

  const blob = await zip.generateAsync({ type: 'blob' });
  saveAs(blob, fileName);
};

export default renderToZip;
