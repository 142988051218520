import { Button, Grid } from '@mui/material';
import { Score, ScoreCount } from 'api';
import React from 'react';
import {
  filterNonActiveScores,
  getInitialsFromScores,
  getWheelPositionCountsFromScores,
} from 'pages/DownloadTeamWheel/scoreUtils';
import downloadSvg from 'pages/DownloadTeamWheel/downloadSVG';
import TeamWheel72 from 'pages/DownloadTeamWheel/TeamWheel72';
import WheelControls from 'pages/DownloadTeamWheel/TeamWheel72Controls';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useWheelContext } from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import TabControls from './TabControls';

const SVG_WHEEL_ID = 'team-wheel-svg';
const RETENTION_CODE = 'Team_Wheel_Product_WC_TW_RETENTION';

type TeamWheelType = 'Experience' | 'Team';

type TabTeamWheelProps = {
  type: TeamWheelType;
  rawScores: Score[];
  name: string;
  availableCount: number;
  reportError: (error: Error, fileName: string) => void;
  wheelPositions: Record<number, ScoreCount> | undefined;
};

const TabTeamWheel = ({
  type,
  rawScores,
  name,
  availableCount,
  reportError,
  wheelPositions,
}: TabTeamWheelProps) => {
  const { t } = useTranslation();
  const [{ scoresGroupingType, i18n, activeOrganisations }] = useWheelContext();

  const activeScores = filterNonActiveScores(rawScores, activeOrganisations);
  const scoresWithInitials = getInitialsFromScores(activeScores);

  const wheelDisplayScores =
    scoresGroupingType === 'DISTINCT'
      ? scoresWithInitials
      : getWheelPositionCountsFromScores(activeScores);

  const handleTeamWheelDownload = () => {
    const filename = `${name}-${type}-wheel-${RETENTION_CODE}.png`;
    downloadSvg(filename, SVG_WHEEL_ID, false, 1500, 1500).catch((error) => {
      reportError(error, filename);
    });
  };

  return (
    <>
      <TabControls>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleTeamWheelDownload}
          disabled={availableCount < 1}
        >
          {t('ui.event-management.events.team-wheel.download-image')}
        </Button>
      </TabControls>
      <Grid container>
        <Grid item xs={12} md={4}>
          <WheelControls />
        </Grid>
        <Grid item xs={12} md={8}>
          <I18nextProvider i18n={i18n}>
            <TeamWheel72
              svgId={SVG_WHEEL_ID}
              scores={wheelDisplayScores}
              wheelPositions={wheelPositions}
            />
          </I18nextProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default TabTeamWheel;
