import { datadogRum } from '@datadog/browser-rum';
import { DATADOG_RUM_CONFIGS } from 'variables/index';

function getRumConfig() {
  return DATADOG_RUM_CONFIGS.find(
    (config) => config.hostname === window.location.host,
  );
}

function datadogEnabled() {
  return import.meta.env.PROD;
}

export function addError(error: unknown, context?: object | undefined) {
  if (!datadogEnabled() || !getRumConfig()) {
    if (context) {
      // eslint-disable-next-line no-console
      console.error(error, context);
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return;
  }

  datadogRum.addError(error, context);
}
