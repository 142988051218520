import React, { useState } from 'react';
import { TextV2 } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { Box, Button, Card, CardContent } from '@mui/material';
import { useBetaEnabled } from 'features';
import SetScheduleShareDialog from 'pages/Event/EventOverview/SetScheduleShareDialog';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventResponse } from 'api';
import { TProfilesCount } from 'pages/Event/types';
import { formatDate, toLocaleString } from '@insights-ltd/design-library/utils';

interface PurchasedProfilesCardProps {
  event: EventResponse;
  profilesStatusCount: TProfilesCount;
  disabled?: boolean;
}

export const PurchasedProfilesCard = ({
  event: propsEvent,
  profilesStatusCount,
  disabled = false,
}: PurchasedProfilesCardProps) => {
  const betaEnabled = useBetaEnabled('schedule-share-feature');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [event, setEvent] = useState(propsEvent);

  const [scheduleShareDialogOpen, setScheduleShareDialogOpen] = useState(false);

  if (!betaEnabled || event.eventType === 'INSIGHTS_EXPLORE') {
    return null;
  }

  const { noOfProfilesReadyToPurchase, noOfProfilesPurchased } =
    profilesStatusCount;
  const evaluatorsComplete =
    noOfProfilesPurchased + noOfProfilesReadyToPurchase;
  const shareable = [
    'INSIGHTS_DISCOVERY_WORKSHOP',
    'INSIGHTS_DISCOVERY_ACCREDITATION',
  ].includes(event.eventType);
  const isShareDisabled = disabled;
  const isPurchaseDisabled = disabled || noOfProfilesReadyToPurchase === 0;

  const shareStatus = () => {
    if (!event.shareProfilesAt) {
      return t('ui.event-management.events.schedule-share.state.unset');
    }
    const date = formatDate(
      event.shareProfilesAt,
      event.timezone,
      event.dialect,
    );
    const time = toLocaleString(
      event.shareProfilesAt,
      event.timezone,
      event.dialect,
      {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      },
    ).toLowerCase();
    return t('ui.event-management.events.schedule-share.state.set', {
      count: noOfProfilesPurchased,
      date,
      time,
    });
  };

  return (
    <>
      <Card
        elevation={0}
        sx={() => ({
          height: '100%',
          opacity: disabled ? '0.5' : 'undefined',
        })}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            justifyContent: 'flex-start',
          }}
        >
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
            <TextV2 component="p" variant="bodyBold">
              {t('ui.event-management.events.profiles-purchased-tile.title')}
            </TextV2>
          </Box>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
            <TextV2 variant="h2" component="p">
              {noOfProfilesPurchased} / {evaluatorsComplete}
            </TextV2>
          </Box>
          {shareable && (
            <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
              <TextV2 variant="body1" component="p">
                {shareStatus()}
              </TextV2>
            </Box>
          )}
          <Button
            onClick={() => navigate(`${pathname}/download-profiles`)}
            fullWidth
            variant="contained"
            color="primary"
            disabled={isPurchaseDisabled}
          >
            {t(
              'ui.event-management.events.profiles-purchased-tile.purchase-button',
            )}
          </Button>
          {shareable && (
            <Button
              onClick={() => setScheduleShareDialogOpen(true)}
              fullWidth
              variant="outlined"
              color="primary"
              disabled={isShareDisabled}
              sx={{ mt: 1 }}
            >
              {event.shareProfilesAt
                ? t('ui.event-management.events.schedule-share.change.button')
                : t('ui.event-management.events.schedule-share.set.button')}
            </Button>
          )}
        </CardContent>
      </Card>
      {shareable && (
        <SetScheduleShareDialog
          event={event}
          setEvent={setEvent}
          open={scheduleShareDialogOpen}
          onClose={() => setScheduleShareDialogOpen(false)}
        />
      )}
    </>
  );
};
