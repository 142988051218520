import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material';
import {
  EventBudget,
  EventResponse,
  EventResponseWithChapters,
  InviteeResponse,
} from 'api';
import { LearnerContributors } from 'api/organisationGroups/organisationGroupsTypes';
import { H1, H2, P } from '@insights-ltd/design-library/components';
import { dateInPast } from '@insights-ltd/design-library/utils';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { useTranslation } from 'react-i18next';
import {
  isDFCExperience,
  isExploreExperience,
  isIdtlExperience,
} from 'domain/event';
import { profilesPurchased, profilesReadyToPurchased } from 'domain/invitee';
import { TProfilesCount } from 'pages/Event/types';
import { isoToLocaleString } from 'utils/dates';
import { readyToPurchaseFilter } from 'utils/eventFilters';
import { PurchasedProfilesCard } from './PurchasedProfilesCard';
import AddLearnersCard from './AddLearnersCard';
import AssignChaptersCard from './AssignChaptersCard';
import EvaluatorRemindersCard from './EvaluatorRemindersCard';
import DownloadProfilesCard from './DownloadProfilesCard';
import DownloadTeamWheelCard from './DownloadTeamWheelCard';
import SendInvitesCard from './SendInvitesCard';
import AddLearnersAndContributorsCard from './AddLearnersAndContributorsCard';
import EventWallet from './EventWallet';

type Props = {
  invitees: InviteeResponse[];
  contributorLearners?: LearnerContributors[];
  event: EventResponse;
  readExpiredIsPermitted: boolean;
};

const StyledDiv = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(spacingSizeMap.L),
  padding: theme.spacing(spacingSizeMap.XS),
  paddingLeft: theme.spacing(spacingSizeMap.S),
  color: theme.palette.error.main,
  background: theme.palette.error.light,
  borderRadius: '4px',
}));

const isBudgetedEvent = (
  event: EventResponse,
): event is EventResponse & { budget: EventBudget } => {
  return event.budget !== undefined && event.budget !== null;
};

const EventOverview = ({
  invitees,
  contributorLearners = [],
  event,
  readExpiredIsPermitted,
}: Props) => {
  const { t } = useTranslation();
  const eventWithChapters = event as EventResponseWithChapters;
  const pastExperience: boolean = dateInPast(event.startsAt, event.timezone);
  const pastExperienceDFC: boolean = dateInPast(event.endsAt, event.timezone);
  const isCancelled: boolean = event.eventStatus === 'CANCELLED';
  const pastEndDate: boolean = dateInPast(event.endsAt, event.timezone);
  const isIDTL = isIdtlExperience(event.eventType);
  const isDFC = isDFCExperience(event.eventType);
  const isExplore = isExploreExperience(event.eventType);
  const budgetedEvent = isBudgetedEvent(event);

  const pastDeadline: boolean =
    event.deadlineAt?.length > 0 &&
    dateInPast(event.deadlineAt, event.timezone);

  const startDateText = isoToLocaleString(event.startsAt, event.dialect);
  const endDateText = isoToLocaleString(event.endsAt, event.dialect);

  const cancelledDateText = event.cancelledAt
    ? isoToLocaleString(event.cancelledAt, event.dialect)
    : null;

  const profilesStatusCount: TProfilesCount = {
    noOfProfilesReadyToPurchase: invitees.filter(profilesReadyToPurchased)
      .length,
    noOfProfilesPurchased: invitees.filter(profilesPurchased).length,
  };

  const learnerStatusCount: TProfilesCount = {
    noOfProfilesReadyToPurchase: contributorLearners.filter(
      readyToPurchaseFilter,
    ).length,
    noOfProfilesPurchased: contributorLearners.filter((contributors) => {
      return contributors.contributors.filter((c) => {
        return (
          c.status !== 'READY_TO_PURCHASE' &&
          c.status !== 'ADDED' &&
          c.status !== 'INVITE_REQUESTED' &&
          c.status !== 'INVITE_SENT'
        );
      }).length;
    }).length,
  };

  const useLearnerCount =
    isDFC && contributorLearners[0]?.contributors.length > 0;

  const profileCost =
    budgetedEvent && eventWithChapters.chapters
      ? eventWithChapters.chapters[0].cost
      : 0;

  const CancelledStyledDiv = styled('div')(({ theme }) => ({
    padding: theme.spacing(spacingSizeMap.XS),
    paddingLeft: '1rem',
    marginBottom: theme.spacing(spacingSizeMap.L),
    '& > p': {
      color: theme.palette.error.main,
    },
    background: theme.palette.error.light,
    borderRadius: '4px',
  }));

  const allContributors = useMemo(
    () => contributorLearners.flatMap(({ contributors }) => contributors),
    [contributorLearners],
  );

  const eventWalletId = event.wallet?.id;
  const eventStatus = event?.eventStatus;
  const eventBalance = event.wallet?.availableUnits || 0;
  const potential = event.budget?.potentialSpend || 0;
  const budget = event.budget?.total || 0;
  const preventInvites = budgetedEvent && budget < potential;
  const disableInvites = pastExperience || isCancelled || preventInvites;
  const cancelledORExpired = pastExperienceDFC || isCancelled || preventInvites;

  return (
    <>
      {!isDFC && pastExperience && !isCancelled && (
        <StyledDiv>
          <P variant="body-bold">
            {t('ui.event-management.events.past-experience.notice', {
              date: startDateText,
            })}
          </P>
        </StyledDiv>
      )}
      {isDFC && pastExperienceDFC && !isCancelled && (
        <StyledDiv>
          <P variant="body-bold">
            {t('ui.event-management.events.dfc.past-experience.notice', {
              date: endDateText,
            })}
          </P>
        </StyledDiv>
      )}
      {isCancelled && (
        <CancelledStyledDiv>
          <P variant="body-bold">
            {cancelledDateText
              ? t(
                  'ui.event-management.events.cancelled-experience.notice.date',
                  {
                    date: cancelledDateText,
                  },
                )
              : t('ui.event-management.events.cancelled-experience.notice')}
          </P>
        </CancelledStyledDiv>
      )}
      <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
        <H1 variant="h2">{t('ui.event-management.events.learners.title')}</H1>
      </Box>
      {budgetedEvent && eventWalletId && (
        <>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
            <H2 variant="h3">
              {t(
                'ui.event-management.events.learners.subtitle.unit-management',
              )}
            </H2>
          </Box>
          <EventWallet
            profileCost={profileCost}
            eventBalance={eventBalance}
            eventWalletId={eventWalletId}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            organisationId={event.organisation!.id}
            eventStatus={eventStatus}
            pastEndDate={pastEndDate}
            budget={event.budget}
          />
        </>
      )}
      <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
        <H2 variant="h3">
          {t('ui.event-management.events.learners.subtitle.actions')}
        </H2>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SendInvitesCard
            addUnitsWarning={preventInvites}
            invitees={isDFC ? allContributors : invitees}
            event={event}
            disabled={disableInvites}
          />
        </Grid>
        {!isIDTL && (
          <Grid item xs={12} sm={6}>
            <EvaluatorRemindersCard
              inviteesStatuses={isDFC ? allContributors : invitees}
              pastDeadline={pastDeadline}
              pastExperience={pastExperience}
              event={event}
              disabled={isCancelled}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <PurchasedProfilesCard
            event={event}
            profilesStatusCount={
              useLearnerCount ? learnerStatusCount : profilesStatusCount
            }
            disabled={cancelledORExpired}
          />
        </Grid>
      </Grid>
      <Box my={(theme) => theme.spacing(spacingSizeMap.L)}>
        <Divider />
      </Box>
      <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
        <H2 variant="h3">
          {t('ui.event-management.events.learners.subtitle.event-tasks')}
        </H2>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {isDFC ? (
            <AddLearnersAndContributorsCard
              disabled={!readExpiredIsPermitted || isCancelled}
              hasLearner={contributorLearners.length >= 1}
              pastExperience={pastExperienceDFC}
              learnersCount={contributorLearners.length}
              contributorsCount={allContributors.length}
            />
          ) : (
            <AddLearnersCard
              noOfInvitees={invitees.length}
              disabled={
                (pastExperience && !readExpiredIsPermitted) || isCancelled
              }
            />
          )}
        </Grid>
        {!isIDTL && !isDFC && !isExplore && (
          <Grid item xs={12} sm={6}>
            <AssignChaptersCard
              invitees={invitees}
              disabled={isCancelled || pastDeadline}
            />
          </Grid>
        )}
        {!isExplore && (
          <Grid item xs={12} sm={6}>
            <DownloadProfilesCard
              disabled={isCancelled}
              profilesStatusCount={
                useLearnerCount ? learnerStatusCount : profilesStatusCount
              }
            />
          </Grid>
        )}
        {!isDFC && (
          <Grid item xs={12} sm={6}>
            {!isExplore && (
              <DownloadTeamWheelCard
                noOfProfilesPurchased={
                  profilesStatusCount.noOfProfilesPurchased
                }
                disabled={isCancelled}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EventOverview;
