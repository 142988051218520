import { H2 } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';
import { QueryStatus } from '@tanstack/react-query';
import { TeamResponse, useGetMyTeams, useGetTeams } from 'api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryStatusIgnoreIdle } from 'utils/combineQueryStatus';

const StyledLearnerCount = styled('span')(({ theme }) => ({
  padding: `0 ${theme.spacing(spacingSizeMap.XS)}`,
  color: theme.palette.grey[100],
  backgroundColor: theme.palette.pink.main,
  borderRadius: '4px',
}));

type Props = {
  searchTerm: string;
  myTeamsOnly?: boolean;
  organisationId?: string | null;
};

const useGetTeamResults = ({
  searchTerm,
  myTeamsOnly = false,
  organisationId,
}: Props): {
  items: TeamResponse[];
  status: QueryStatus;
  limitExceeded: boolean;
  isFetched: boolean;
  title: string | JSX.Element;
} => {
  const { t } = useTranslation();
  const getTeamsQuery = useGetTeams(
    organisationId,
    searchTerm.length >= 2 ? searchTerm : '',
    !myTeamsOnly,
  );
  const getMyTeamsQuery = useGetMyTeams(
    organisationId,
    searchTerm.length >= 2 ? searchTerm : '',
    myTeamsOnly,
  );

  const activeQuery = myTeamsOnly ? getMyTeamsQuery : getTeamsQuery;

  const { data, isFetched } = activeQuery;
  const status = queryStatusIgnoreIdle(activeQuery);

  const items = data?.teams ?? [];
  const limitExceeded = !!data?.limitExceeded;

  const myTeamsTitle = isFetched ? (
    <H2 variant="h3">
      {searchTerm.length < 2 ? (
        <>
          {t('ui.event-management.learners.my-teams.heading-default')}
          &nbsp;
          <StyledLearnerCount>{items?.length ?? 0}</StyledLearnerCount>
        </>
      ) : (
        <>
          {t('ui.event-management.learners.my-teams.heading', {
            searchTerm,
          })}
          &nbsp;
          <StyledLearnerCount>{items?.length ?? 0}</StyledLearnerCount>
        </>
      )}
    </H2>
  ) : (
    t('ui.event-management.learners.teams.list.no-search-term')
  );

  const allTeamsTitle = isFetched ? (
    <H2 variant="h3">
      {searchTerm
        ? t('ui.event-management.learners.filtered-teams', {
            searchTerm,
          })
        : t('ui.event-management.learners.all-teams')}
      &nbsp;
      <StyledLearnerCount>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {data?.limitExceeded ? '300+' : items!.length}
      </StyledLearnerCount>
    </H2>
  ) : (
    t('ui.event-management.learners.teams.list.no-search-term')
  );

  return {
    items,
    status,
    limitExceeded,
    isFetched,
    title: myTeamsOnly ? myTeamsTitle : allTeamsTitle,
  };
};

export default useGetTeamResults;
