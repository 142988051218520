import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ssoRedirect } from 'api/sso/sso';
import { QUERY_KEY_ROOTS } from 'variables/index';

export const useSsoRedirect = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ssoRedirect,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PRACTITIONER_DATA],
      });
    },
  });
};
