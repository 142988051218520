import {
  Alert,
  Dialog,
  RadioGroupControl,
  Select,
  Span,
  Text,
} from '@insights-ltd/design-library/components';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  ShareType,
  useDownloadTeamProfiles,
  useGetDiscoveryProfileIds,
  useShareProfiles,
} from 'api';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';
import { EmailProgressDialogV2 } from 'components/EmailProgressDialogV2';
import DownloadingProfilesDialog from 'components/DownloadingToS3Dialog/DownloadingToS3Dialog';
import { AllDialectOptions, ProfileDialectStrategies } from 'types/dialects';
import { DIALECT_CODES } from 'variables';
import { getEvaluatorDialects, useFormatEvaluatorDialect } from 'domain/teams';
import { LicensedProduct } from 'types/types';

type RadioType = 'share' | 'download';
type DialogProps = {
  isCheckProfilesLoading: boolean;
  profileIdsCount: number;
  shareMethod: ShareType;
  radioValue: RadioType;
  setShareMethod: (value: ShareType) => void;
  setRadioValue: React.Dispatch<React.SetStateAction<RadioType>>;
  setSelectedDialect: (value: AllDialectOptions) => void;
  selectedDialect: AllDialectOptions;
  productType?: LicensedProduct;
};

const DialogContent = ({
  isCheckProfilesLoading,
  profileIdsCount,
  shareMethod,
  radioValue,
  setShareMethod,
  setRadioValue,
  setSelectedDialect,
  selectedDialect,
  productType = 'DISCOVERY_PERSONAL_PROFILE',
}: DialogProps) => {
  const { t } = useTranslation();
  const actionLabel =
    radioValue === 'share'
      ? t(
          'ui.event-management.events.download-or-share-profiles.pereferred-language-label',
        )
      : t(
          'ui.event-management.events.download-or-share-profiles.download.preferred-language-label',
        );

  const evaluatorDialects = getEvaluatorDialects(productType);

  const dialectOptions = useFormatEvaluatorDialect(evaluatorDialects);

  if (isCheckProfilesLoading) return null;
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginLeft: '-4px',
      }}
      spacing={1}
      justifyContent="center"
      alignContent="center"
    >
      <Grid item>
        <Text>
          <Trans
            i18nKey="ui.event-management.events.download-or-share-profiles-modal.found-learners"
            components={{ bold: <Span variant="body-bold" /> }}
            values={{
              count: profileIdsCount,
            }}
          />
        </Text>
      </Grid>
      <Grid item>
        <RadioGroupControl
          data-testid="share-or-download-option"
          name="ShareOrDownloadOptions"
          title="Share or download options"
          onChange={(value) => setRadioValue(value)}
          value={radioValue}
          options={[
            {
              value: 'share',
              label: t(
                'ui.event-management.events.download-or-share-profiles-modal.radio.share-or-download',
              ),
            },
            {
              value: 'download',
              label: t(
                'ui.event-management.events.download-or-share-profiles-modal.radio.download-latest-profiles',
              ),
            },
          ]}
        />
      </Grid>
      <Grid item>
        <Text variant="body-bold">{actionLabel}</Text>
        <Select
          id="download-share-language-select"
          items={{
            ...dialectOptions,
          }}
          fullWidth
          value={selectedDialect}
          disabled={radioValue === 'share'}
          onChange={(value) =>
            setSelectedDialect(value.target.value as AllDialectOptions)
          }
        />
      </Grid>

      {radioValue === 'share' && (
        <>
          <Grid item>
            <Alert>
              {t(
                'ui.event-management.events.download-or-share-profiles-modal.info-message',
              )}
            </Alert>
          </Grid>
          <Grid item>
            <RadioGroupControl
              name="radio-share-method"
              title={t(
                'ui.event-management.events.download-or-share-profiles-modal.radio.share.aria-label',
              )}
              onChange={(value) => setShareMethod(value)}
              value={shareMethod}
              options={[
                {
                  value: 'link',
                  label: t(
                    'ui.event-management.events.download-or-share-profiles-modal.radio.share-download-link',
                  ),
                },
                {
                  value: 'attachment',
                  label: t(
                    'ui.event-management.events.download-or-share-profiles-modal.radio.share-download-pdf',
                  ),
                },
              ]}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export const DownloadShareModal = ({
  isOpen,
  setIsOpen,
  teamId,
  learnerIds,
  setCheckedItems,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  teamId: string;
  learnerIds: string[];
  setCheckedItems?: (value: any) => void;
}) => {
  const { t } = useTranslation();
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [shareMethod, setShareMethod] = useState<ShareType>('link');
  const [radioValue, setRadioValue] = useState<RadioType>('share');
  const [selectedDialect, setSelectedDialect] =
    useState<AllDialectOptions>('evaluation-dialect');

  const {
    isPending: isCheckProfilesPending,
    data: profileIdsData = { profileIds: [] },
    isError: isCheckProfilesError,
  } = useGetDiscoveryProfileIds(teamId, learnerIds, { enabled: isOpen });
  const { isError: isShareError, mutate: shareProfiles } = useShareProfiles();
  const { isError: isDownloadError, mutate: downloadProfiles } =
    useDownloadTeamProfiles();
  const { openErrorModal } = useRequestErrorContext();

  if (isCheckProfilesPending) return null;

  const { profileIds } = profileIdsData;
  const profileIdsCount = profileIds?.length || 0;
  const hasLearnersWithIDPP = profileIdsCount > 0;

  const confirmButtonText =
    radioValue === 'share'
      ? t(
          'ui.event-management.events.download-or-share-profiles-modal.button.share-profile',
          { count: profileIdsCount },
        )
      : t(
          'ui.event-management.events.download-or-share-profiles-modal.button.download-profile',
          { count: profileIdsCount },
        );

  if (isCheckProfilesError || isShareError || isDownloadError) {
    setIsOpen(false);
    openErrorModal();
  }
  function generateUsing() {
    return (
      selectedDialect === DIALECT_CODES.EVALUATION_DIALECT
        ? 'EVALUATION_DIALECT'
        : 'SINGLE_DIALECT'
    ) as ProfileDialectStrategies;
  }

  return (
    <>
      <Dialog
        open={isOpen}
        title={t('ui.event-management.events.download-or-share-profiles')}
        content={
          <DialogContent
            isCheckProfilesLoading={isCheckProfilesError}
            profileIdsCount={profileIdsCount}
            shareMethod={shareMethod}
            radioValue={radioValue}
            setShareMethod={setShareMethod}
            setRadioValue={setRadioValue}
            setSelectedDialect={setSelectedDialect}
            selectedDialect={selectedDialect}
          />
        }
        onClose={() => {
          setIsOpen(false);
          if (setCheckedItems) {
            setCheckedItems({});
          }
        }}
        disablePrimaryButton={!hasLearnersWithIDPP}
        primaryButtonText={confirmButtonText}
        secondaryButtonText={t('ui.event-management.cancel')}
        onSubmit={() => {
          if (hasLearnersWithIDPP) {
            const body =
              selectedDialect === 'evaluation-dialect'
                ? { profileIds }
                : {
                    profileIds,
                    dialect: selectedDialect,
                    generateUsing: generateUsing(),
                  };
            if (radioValue === 'share') {
              shareProfiles(
                { teamId, body, shareType: shareMethod },
                {
                  onSuccess: () => {
                    setOpenEmailDialog(true);
                    setIsOpen(false);
                  },
                },
              );
            }
            if (radioValue === 'download') {
              downloadProfiles(
                { teamId, body },
                {
                  onSuccess: () => {
                    setOpenDownloadDialog(true);
                    setIsOpen(false);
                  },
                },
              );
            }
          }
        }}
      />
      <EmailProgressDialogV2
        open={openEmailDialog}
        onClose={() => {
          setOpenEmailDialog(false);
          setIsOpen(false);
        }}
        title={t(
          'ui.event-management.events.download-or-share-profiles.confirmation-modal',
        )}
        header={t(
          'ui.event-management.events.download-or-share-profiles.confirmation-modal.header',
        )}
        body={t(
          'ui.event-management.events.download-or-share-profiles.confirmation-modal.body',
        )}
      />
      <DownloadingProfilesDialog
        open={openDownloadDialog}
        onClose={() => {
          setOpenDownloadDialog(false);
          setIsOpen(false);
        }}
      />
    </>
  );
};
