import { Box } from '@mui/material';
import React from 'react';
import { ScoreType } from 'types/types';
import ChartDownload from 'pages/DownloadTeamWheel/TeamEnergyChartDownload';
import downloadSvg from 'pages/DownloadTeamWheel/downloadSVG';
import TeamEnergyChart from 'pages/DownloadTeamWheel/TeamEnergyChart';
import { I18nextProvider } from 'react-i18next';
import { useWheelContext } from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import { BothPercentages } from 'pages/DownloadTeamWheel/scoreUtils';
import TabControls from './TabControls';

const SVG_PREFERRED_ID = 'preferred-energies-svg';
const SVG_DOMINANT_ID = 'dominant-energies-svg';
const RETENTION_CODE = 'Team_Wheel_Product_WC_TW_RETENTION';

type TabTeamEnergyChartProps = {
  percentages: BothPercentages;
  name: string;
  availableCount: number;
  reportError: (error: Error, fileName: string) => void;
};

const TabTeamEnergyChart = ({
  percentages,
  name,
  availableCount,
  reportError,
}: TabTeamEnergyChartProps) => {
  const [{ i18n }] = useWheelContext();
  const handleDownloadPreferred = (downloadScoreType: ScoreType) => {
    const filename = `${name}-preferred-energies-${downloadScoreType}-${RETENTION_CODE}.png`;
    downloadSvg(filename, SVG_PREFERRED_ID, false, 1440, 1170).catch(
      (error) => {
        reportError(error, filename);
      },
    );
  };

  const handleDownloadDominant = (downloadScoreType: ScoreType) => {
    const filename = `${name}-dominant-energies-${downloadScoreType}-${RETENTION_CODE}.png`;
    downloadSvg(filename, SVG_DOMINANT_ID, false, 1440, 1170).catch((error) => {
      reportError(error, filename);
    });
  };

  return (
    <>
      <TabControls>
        <ChartDownload
          disabled={availableCount < 1}
          handleDownloadDominant={handleDownloadDominant}
          handleDownloadPreferred={handleDownloadPreferred}
        />
      </TabControls>
      <Box>
        <I18nextProvider i18n={i18n}>
          <TeamEnergyChart
            svgId={SVG_DOMINANT_ID}
            percentages={percentages.dominant}
            chartTitleKey="ui.event-management.events.team-wheel.dominant-chart-title"
          />
          <TeamEnergyChart
            svgId={SVG_PREFERRED_ID}
            percentages={percentages.preferred}
            chartTitleKey="ui.event-management.events.team-wheel.preferred-chart-title"
          />
        </I18nextProvider>
      </Box>
    </>
  );
};

export default TabTeamEnergyChart;
