const renderBlobToBase64 = async (blob: Blob): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    try {
      const reader: FileReader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    } catch (e) {
      reject(e);
    }
  });
};

export default renderBlobToBase64;
