import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Helmet } from 'react-helmet';
import Link from '@mui/material/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  ButtonV2,
  CheckboxV2,
  H1,
  InputText,
  InputType,
  P,
  Span,
  Text,
} from '@insights-ltd/design-library/components';
import SelfServiceLayout from 'components/layout/SelfServiceLayout';
import { useSignIn as useSignInQuery } from 'api/practitioners/practitionerHooks';
import { useQueryClient } from '@tanstack/react-query';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type SignInFormData = {
  emailAddress: string;
  password: string;
};

const isErrorStatus = (isError: boolean, error: unknown, statusCode: string) =>
  isError && error instanceof Error && error.message === statusCode;

const useSignIn = () => {
  const queryClient = useQueryClient();
  const { isError, error, ...rest } = useSignInQuery(queryClient);
  const isForbidden = isErrorStatus(isError, error, '403');
  const isUnauthorized = isErrorStatus(isError, error, '401');
  return {
    ...rest,
    isForbidden,
    isUnauthorized,
  };
};

const EmailRequiredLabel = () => (
  <Trans
    i18nKey="ui.event-management.sign-in.email.input-label-text"
    components={{
      bold: <Text color="textPrimary" variant="body-bold" />,
      styles: <Text color="error" variant="body-bold" />,
    }}
  />
);

const PasswordRequiredLabel = () => (
  <Trans
    i18nKey="ui.event-management.sign-in.password.input-label-text"
    components={{
      bold: <Text color="textPrimary" variant="body-bold" />,
      styles: <Text color="error" variant="body-bold" />,
    }}
  />
);

const SignIn = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const { mutate, isPending, isUnauthorized, isForbidden } = useSignIn();
  const emailAddress = state?.email || '';
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormData>({ defaultValues: { emailAddress } });

  const onSubmit = (credentials: SignInFormData) => mutate(credentials);

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.sign-in')}</title>
      </Helmet>
      <SelfServiceLayout>
        <Paper
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(spacingSizeMap.S),
            flexDirection: 'column',
            width: '100%',
            maxWidth: '31rem',
            marginBottom: theme.spacing(spacingSizeMap.L),
            padding: theme.spacing(spacingSizeMap.L),
          })}
        >
          <H1 variant="h3">{t('ui.event-management.sign-in.heading')}</H1>
          <P variant="body" color="textSecondary">
            <Trans
              i18nKey="ui.event-management.sign-in.sub-heading.bold"
              components={{ bold: <Span variant="body-bold" /> }}
            />
          </P>
          {isForbidden && (
            <Box my={1}>
              <Alert severity="error" variant="standard" icon={false}>
                <Span variant="body-bold" color="error">
                  <Trans
                    i18nKey="ui.event-management.sign-in.account-inactive.error"
                    values={{ emailAddress: 'customerservice@insights.com' }}
                    components={[
                      <Link
                        key="1"
                        href="mailto:customerservice@insights.com"
                      />,
                    ]}
                  />
                </Span>
              </Alert>
            </Box>
          )}
          <InputText
            id="emailAddress"
            type={InputType.EMAIL}
            label={<EmailRequiredLabel />}
            placeholder={t(
              'ui.event-management.users.create.email.placeholder',
            )}
            error={Boolean(errors.emailAddress)}
            fullWidth
            helperText={
              errors.emailAddress &&
              t('ui.event-management.sign-in.email.error.required-polite')
            }
            aria-labelledby={undefined}
            aria-describedby={undefined}
            autoFocus={!emailAddress}
            {...register('emailAddress', { required: true })}
          />
          <InputText
            id="password"
            autoComplete="off"
            label={<PasswordRequiredLabel />}
            type={showPassword ? InputType.TEXT : InputType.PASSWORD}
            error={Boolean(errors.password)}
            fullWidth
            placeholder={t(
              'ui.event-management.sign-up.password.input-placeholder',
            )}
            helperText={
              errors.password &&
              t('ui.event-management.sign-in.password.error.required-polite')
            }
            aria-labelledby={undefined}
            aria-describedby={undefined}
            autoFocus={!!emailAddress}
            {...register('password', { required: true })}
          />
          <CheckboxV2
            id="showPassword"
            label="Show password"
            checked={showPassword}
            onChange={(value) => setShowPassword(value)}
          />
          <P variant="body-bold">
            <Link
              component={RouterLink}
              to="/forgotten-password"
              state={{ ssoFlow: true }}
            >
              {t('ui.event-management.sign-in.forgotten-password.link')}
            </Link>
          </P>
          <P variant="body-bold">
            <Link component={RouterLink} to="/signin/start">
              {t('ui.event-management.sign-in.sso.back-to-sso')}
            </Link>
          </P>
          <ButtonV2
            buttonText={t('ui.event-management.sign-in.cta')}
            color="primary"
            disabled={isPending}
            type="submit"
            size="large"
            sx={{ alignSelf: 'end' }}
          />
          <Snackbar open={isUnauthorized} autoHideDuration={6000}>
            <Alert elevation={6} variant="filled" severity="error">
              {t('ui.event-management.sign-in.credentials.error')}
            </Alert>
          </Snackbar>
        </Paper>
      </SelfServiceLayout>
    </>
  );
};

export default SignIn;
